
import { defineComponent, onMounted } from 'vue';
import LogoSVG from '@/assets/on-the-edge-logo.svg?inline';
import { useSignalR } from '@quangdao/vue-signalr';
import { ResumeSession, StartSession } from '@/config/actions';
import { User } from '@/models';
import { BackgroundService } from '@/services';
import { HomeBackground } from '@/config/backgrounds';
import { AdminUrl } from '@/config/auth';

export default defineComponent({
	components: {
		LogoSVG
	},
	props: {
		user: {
			type: Object as () => User,
			default: null
		}
	},
	emits: ['started', 'how-to'],
	setup(_, { emit }) {
		const signalr = useSignalR();

		onMounted(() => {
			BackgroundService.set(HomeBackground);
		});

		const resumeSession = async () => {
			const result = await signalr.invoke(ResumeSession);

			emit('started', result);
		};

		const createSession = async () => {
			const result = await signalr.invoke(StartSession);

			emit('started', result);
		};

		const howToPlay = () => emit('how-to');

		return { AdminUrl, resumeSession, createSession, howToPlay };
	}
});
