
import { Scenario } from '@/models';
import { BackgroundService } from '@/services';
import { defineComponent, onMounted } from 'vue';
import ScenarioText from '@/views/shared/ScenarioText.vue';

export default defineComponent({
	components: { ScenarioText },
	emits: ['completed'],
	props: {
		scenario: {
			type: Object as () => Scenario,
			default: null
		}
	},
	setup(props, { emit }) {
		const scenario = props.scenario as Scenario;

		onMounted(() => {
			BackgroundService.set(scenario.category.imageUrl);
		});

		const complete = () => emit('completed');

		return {
			complete
		};
	}
});
