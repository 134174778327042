
import { ToggleScore } from '@/config/actions';
import { Player } from '@/models';
import { useSignalR } from '@quangdao/vue-signalr';
import { computed, defineComponent } from 'vue';

const CONDENSED_VIEW_THRESHOLD = 12;

export type PlayersDisplayMode = 'none' | 'players' | 'leaderboard';

export default defineComponent({
	props: {
		mode: {
			type: String as () => PlayersDisplayMode,
			default: null
		},
		players: {
			type: Array as () => Player[],
			default: null
		}
	},
	setup(props) {
		const signalr = useSignalR();
		const rootClasses = computed(() => ({
			players: true,
			leaderboard: props.mode === 'leaderboard',
			condensed: playerView.value.players.length > CONDENSED_VIEW_THRESHOLD
		}));
		const title = computed(() =>
			props.mode === 'leaderboard' ? 'Leaderboard' : 'Players'
		);

		const playerView = computed(() => {
			const allPlayers = [...props.players];

			const playersToShow =
				props.mode === 'leaderboard'
					? allPlayers
					: [...allPlayers].reverse().slice(0, 4);
			const excess = allPlayers.length - playersToShow.length;

			return {
				players: playersToShow,
				excess
			};
		});

		const award = (playerId: number) => signalr.send(ToggleScore, playerId);

		return { rootClasses, title, award, playerView };
	}
});
