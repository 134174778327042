
import { ResumeSession, StartSession } from '@/config/actions';
import { HomeBackground } from '@/config/backgrounds';
import { User } from '@/models/user';
import { BackgroundService } from '@/services';
import { useSignalR } from '@quangdao/vue-signalr';
import { defineComponent, onMounted, ref } from 'vue';

// TODO: This will be configurable.
const htmlContent = `<h3>Starting the Game</h3>
<ul>
  <li>Participants will join using a browser on their own devices. They will need to go to
    <a href="https://play.ontheedgegame.com/" target="_blank"
      >https://play.ontheedgegame.com/</a>
    and enter the room code provided by the facilitator. The room code is not case sensitive.
  </li>
  <li>*Must be a minimum of three people to play the game 1-facilitator and 2 participants.</li>
  <li>Once all participants have joined, the facilitator will select the <strong>“Everyone’s Here”</strong> button to start the
    game.</li>
  <li>Players will be automatically selected in random order to choose a category. Once the random player is selected, they will select <strong>“Let’s Go”</strong> on their screen. </li>
  <li>Participants will pick a category. The name of the person selected will be shown in the bottom left
    corner.</li>
</ul>

<h3>Playing the Game</h3>
<ul>
  <li>The participant will have the opportunity to select skip or discuss.</li>
  <li>Each
    player has the ability to skip 3 scenarios for the duration of the
    game. Once a player has used all their skips they will receive a prompt
    <strong>Sorry - you are all out of skips</strong>!
  </li>
  <li>Other players can select “<strong>raise their hand</strong>” to indicate they want to contribute to the discussion.
    </li>
  <li>The facilitator should call on players with raised hands. Once the discussion has ended the facilitator must
    select the “<strong>Next Question</strong>” link on the bottom right of the screen. This will advance to the next random
    player to choose a category. </li>
</ul>

<h3>Ending the Game</h3>
<p>The facilitator can end the game two ways.</p>
<ol>
  <li>The facilitator can choose to end the game at any point by selecting the “<strong>End Game</strong>” link from the category
    screen at the bottom right.</li>
  <li>The
    facilitator can choose to close the browser. This will keep the session
    active to allow the facilitator to resume the session where they ended
    for the next time they play
  </li>
</ol>
<h3>Earning Points</h3>
<ul>
  <li><span>Players earn a point automatically when they enable a question for
      discussion<strong>.(Quangdo is true? It wasn’t happening when I was playing the game)</strong></span>
  </li>
  <li>Players can earn additional point(s) for each discussion prompt when they select “<b>raise their hand”
    </b>and contribute toward.</li>
  <li>Facilitator must select the hand for points to be added.</li>
  <li>When
    a player “raises their hand”, the facilitator must click on the hand
    icon next to the contributing participant to award the point. A
    checkmark will replace the hand icon to indicate that the point has been
    awarded.</li>
</ul>
<h3>Facilitator Tips</h3>
<p>
  To bypass a player, click on their name in the lower left corner of the
  category screen. This will initiate the random selection of another
  player to select a category.
</p>
<ul>
  <li>Allow participants to answer questions without judging.</li>
  <li>Allow everyone who wants to comment to speak before providing facilitator input.</li>
  <li>Help participants to walk through the risk and/or consequences of both sides of the decision.</li>
</ul>`;

export default defineComponent({
	props: {
		user: {
			type: Object as () => User,
			default: null
		}
	},
	emits: ['started', 'returned'],
	setup(_, { emit }) {
		const content = ref(htmlContent);
		const signalr = useSignalR();

		onMounted(() => {
			BackgroundService.set(HomeBackground);
		});

		const resumeSession = async () => {
			const result = await signalr.invoke(ResumeSession);

			emit('started', result);
		};

		const createSession = async () => {
			const result = await signalr.invoke(StartSession);

			emit('started', result);
		};

		const goBack = () => emit('returned');

		return { content, resumeSession, createSession, goBack };
	}
});
