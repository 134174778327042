
import { EndBackground } from '@/config/backgrounds';
import { GameStats } from '@/models';
import { BackgroundService } from '@/services';
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
	props: {
		stats: {
			type: Object as () => GameStats,
			default: null
		}
	},
	emits: ['restarted'],
	setup(_, { emit }) {
		const invokeAction = () => emit('restarted');

		onMounted(() => BackgroundService.set(EndBackground));

		return { invokeAction };
	}
});
