import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "screen screen-overlay" }
const _hoisted_2 = { class: "help-container" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "help-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "help-content",
        innerHTML: _ctx.content
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.user.hasActiveSession)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("button", {
                class: "start btn",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.resumeSession && _ctx.resumeSession(...args)))
              }, "Resume Game"),
              _createElementVNode("button", {
                class: "start btn",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.createSession && _ctx.createSession(...args)))
              }, " Start New Game ")
            ], 64))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "start btn",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.createSession && _ctx.createSession(...args)))
            }, "Start Game"))
      ])
    ]),
    _createElementVNode("button", {
      class: "btn-link action-right",
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
    }, "Back to Intro")
  ]))
}