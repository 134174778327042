
import { DefaultBackground } from '@/config/backgrounds';
import { Session } from '@/models';
import { BackgroundService } from '@/services';
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
	props: {
		session: {
			type: Object as () => Session,
			default: null
		}
	},
	emits: ['started'],
	setup(_, { emit }) {
		const url = window.location.host;

		onMounted(() => {
			BackgroundService.set(DefaultBackground);
		});

		const startGame = async () => {
			emit('started');
		};

		return {
			url,
			startGame
		};
	}
});
