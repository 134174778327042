import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/check.svg'
import _imports_1 from '@/assets/icons/hand.svg'


const _withScopeId = n => (_pushScopeId("data-v-2bc33118"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "players-list" }
const _hoisted_2 = { class: "player" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "icon",
  src: _imports_0,
  alt: "Scenario answered"
}
const _hoisted_5 = {
  key: 1,
  class: "icon",
  src: _imports_1,
  alt: "Hand raised"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = ["title"]
const _hoisted_8 = {
  key: 1,
  class: "player-score"
}
const _hoisted_9 = {
  key: 0,
  class: "players-excess"
}
const _hoisted_10 = {
  key: 1,
  class: "players-empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.rootClasses)
  }, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playerView.players, (player) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "player-container",
          key: player.id
        }, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.mode === 'leaderboard')
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "player-actions",
                  onClick: ($event: any) => (_ctx.award(player.id))
                }, [
                  (player.questionAnswered)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_4))
                    : (player.handRaised)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                      : _createCommentVNode("", true)
                ], 8, _hoisted_3))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "player-avatar",
              style: _normalizeStyle({ '--avatar-background': player.avatarBackgroundColor })
            }, [
              _createElementVNode("img", {
                src: player.avatarImageUrl,
                alt: "Player Avatar"
              }, null, 8, _hoisted_6)
            ], 4),
            _createElementVNode("div", {
              class: "player-name",
              title: player.name
            }, _toDisplayString(player.name), 9, _hoisted_7),
            (_ctx.mode === 'leaderboard')
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(player.score), 1))
              : _createCommentVNode("", true)
          ])
        ]))
      }), 128)),
      (_ctx.playerView.excess > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, " +" + _toDisplayString(_ctx.playerView.excess) + " Others ", 1))
        : _createCommentVNode("", true),
      (!_ctx.playerView.players.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, " No players yet :( "))
        : _createCommentVNode("", true)
    ])
  ], 2))
}