
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import PlayerList, { PlayersDisplayMode } from './components/PlayerList.vue';
import StartScreen from './screens/Start.vue';
import HelpScreen from './screens/Help.vue';
import LobbyScreen from './screens/Lobby.vue';
import CategoriesScreen from './screens/Categories.vue';
import ScenarioScreen from './screens/Scenario.vue';
import EndScreen from './screens/End.vue';
import { Session, User, Player, Scenario, GameStats } from '@/models';
import { AuthService, ApiService, NoticeService } from '@/services';
import router, { SubscribeRoute } from '@/router';
import { useSignalR } from '@quangdao/vue-signalr';
import {
	PlayerJoined,
	PlayerLeft,
	PlayerSelected,
	ScenarioSelected,
	StartRound,
	SessionUpdated,
	GameOver,
	StartSession,
	PlayerUpdated
} from '@/config/actions';

type States =
	| 'idle'
	| 'start'
	| 'help'
	| 'lobby'
	| 'categories'
	| 'scenario'
	| 'end';

export default defineComponent({
	components: {
		PlayerList,
		StartScreen,
		HelpScreen,
		LobbyScreen,
		CategoriesScreen,
		ScenarioScreen,
		EndScreen
	},
	setup() {
		const signalr = useSignalR();
		const user = ref<User>(null);
		const state = ref<States>('idle');
		const stats = ref<GameStats>(null);
		const session = ref<Session>(null);
		const players = reactive<Player[]>([]);
		const playersListDisplayMode = computed<PlayersDisplayMode>(() => {
			switch (state.value) {
				case 'lobby':
					return 'players';
				case 'scenario':
					return 'leaderboard';
				default:
					return 'none';
			}
		});
		const active = ref<Player>(null);
		const scenario = ref<Scenario>(null);

		const createSession = async () => {
			players.length = 0;
			session.value = null;
			active.value = null;
			scenario.value = null;
			const result = await signalr.invoke(StartSession);

			startSession(result);
		};

		const startSession = (response: Session) => {
			session.value = response;
			state.value = 'lobby';
		};

		const startRound = () => {
			signalr.send(StartRound);
		};

		signalr.on(SessionUpdated, updated => {
			session.value = updated;
			players.length = 0;
			for (const p of updated.players) players.push(p);
		});

		signalr.on(PlayerJoined, player => {
			players.push(player);

			if (playersListDisplayMode.value === 'none') {
				NoticeService.add({
					type: 'info',
					title: 'Player Joined',
					message: `${player.name} has joined the game.`
				});
			}
		});

		signalr.on(PlayerLeft, playerId => {
			const index = players.findIndex(e => e.id === playerId);
			if (index > -1) players.splice(index, 1);
		});

		signalr.on(PlayerSelected, player => {
			active.value = player;
			state.value = 'categories';
		});

		signalr.on(PlayerUpdated, player => {
			const found = players.find(p => p.id === player.id);
			Object.assign(found, player);
		});

		signalr.on(ScenarioSelected, selected => {
			scenario.value = selected;
			state.value = 'scenario';
		});

		signalr.on(GameOver, gameStats => {
			players.length = 0;
			session.value = null;
			active.value = null;
			scenario.value = null;
			stats.value = gameStats;
			state.value = 'end';
		});

		onMounted(async () => {
			const loggedIn = await AuthService.isLoggedIn();
			if (!loggedIn) {
				AuthService.login();
				return;
			}

			try {
				user.value = await ApiService.getUser();
			} catch {
				AuthService.logout();
				return;
			}

			if (!user.value.license) {
				router.push({ name: SubscribeRoute });
				return;
			}

			state.value = 'start';
		});

		return {
			user,
			state,
			session,
			playersListDisplayMode,
			players,
			active,
			scenario,
			stats,
			createSession,
			startSession,
			startRound
		};
	}
});
