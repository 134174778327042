import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d6e7b89"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 7,
  class: "action-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_start_screen = _resolveComponent("start-screen")!
  const _component_help_screen = _resolveComponent("help-screen")!
  const _component_lobby_screen = _resolveComponent("lobby-screen")!
  const _component_categories_screen = _resolveComponent("categories-screen")!
  const _component_scenario_screen = _resolveComponent("scenario-screen")!
  const _component_end_screen = _resolveComponent("end-screen")!
  const _component_player_list = _resolveComponent("player-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.state === 'start')
      ? (_openBlock(), _createBlock(_component_start_screen, {
          key: 0,
          user: _ctx.user,
          onStarted: _ctx.startSession,
          onHowTo: _cache[0] || (_cache[0] = ($event: any) => (_ctx.state = 'help'))
        }, null, 8, ["user", "onStarted"]))
      : _createCommentVNode("", true),
    (_ctx.state === 'help')
      ? (_openBlock(), _createBlock(_component_help_screen, {
          key: 1,
          user: _ctx.user,
          onStarted: _ctx.startSession,
          onReturned: _cache[1] || (_cache[1] = ($event: any) => (_ctx.state = 'start'))
        }, null, 8, ["user", "onStarted"]))
      : _createCommentVNode("", true),
    (_ctx.state === 'lobby')
      ? (_openBlock(), _createBlock(_component_lobby_screen, {
          key: 2,
          session: _ctx.session,
          onStarted: _ctx.startRound
        }, null, 8, ["session", "onStarted"]))
      : _createCommentVNode("", true),
    (_ctx.state === 'categories')
      ? (_openBlock(), _createBlock(_component_categories_screen, {
          key: 3,
          player: _ctx.active
        }, null, 8, ["player"]))
      : _createCommentVNode("", true),
    (_ctx.state === 'scenario')
      ? (_openBlock(), _createBlock(_component_scenario_screen, {
          key: 4,
          scenario: _ctx.scenario,
          onCompleted: _ctx.startRound
        }, null, 8, ["scenario", "onCompleted"]))
      : _createCommentVNode("", true),
    (_ctx.state === 'end')
      ? (_openBlock(), _createBlock(_component_end_screen, {
          key: 5,
          onRestarted: _ctx.createSession,
          stats: _ctx.stats
        }, null, 8, ["onRestarted", "stats"]))
      : _createCommentVNode("", true),
    (_ctx.playersListDisplayMode !== 'none')
      ? (_openBlock(), _createBlock(_component_player_list, {
          key: 6,
          mode: _ctx.playersListDisplayMode,
          players: _ctx.players
        }, null, 8, ["mode", "players"]))
      : _createCommentVNode("", true),
    (_ctx.session && _ctx.state != 'lobby')
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, " Room Code: " + _toDisplayString(_ctx.session.roomCode), 1))
      : _createCommentVNode("", true)
  ], 64))
}