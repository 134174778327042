
import { defineComponent, onMounted } from 'vue';
import CategoriesList from '@/views/shared/CategoriesList.vue';
import { Player } from '@/models';
import { BackgroundService } from '@/services';
import { DefaultBackground } from '@/config/backgrounds';
import { useSignalR } from '@quangdao/vue-signalr';
import { EndGame, StartRound } from '@/config/actions';

export default defineComponent({
	props: {
		player: {
			type: Object as () => Player,
			default: null
		}
	},
	components: {
		CategoriesList
	},
	setup() {
		const signalr = useSignalR();
		onMounted(() => BackgroundService.set(DefaultBackground));

		const refresh = () => signalr.send(StartRound);
		const endGame = () => signalr.send(EndGame);

		return { refresh, endGame };
	}
});
